<template>

	<div class="main-contents">
		<div class="mem_container resume">
			<div class="mem_info_contents">
				<div class="mem_contents_header">
					<div v-if="!mberDiv && isReg == true" class="bt-list mt-8">
						<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1)">
					</div>
					<div v-if="isReg == true && mberDiv" class="title-txt">공급기업 지정 프로젝트 &gt; 프로젝트 등록<br/>
						<p><span class="prj_ct">등록하실 프로젝트의 내용을 상세히 입력 해 주세요!</span></p>
					</div>
					<div v-else-if="isReg == true && !mberDiv" class="title-txt">프로젝트 등록<br/>
						<!-- <p><span class="prj_ct">등록하실 프로젝트의 내용을 상세히 입력 해 주세요!</span></p> -->
					</div>
					<div v-else class="title-txt">프로젝트 수정<br/>
						<p><span class="prj_ct">수정 내용에 따라 매니저 상담이 필요할 수 있습니다!</span></p>
						<!-- <p><span class="prj_ct">프로젝트 수정 내용에 따라 담당 매니저와 상담이 필요할 수 있음을 알려드립니다!</span></p> -->
					</div>
				</div>
				<!-- <div class="myprj_tab"><img src="/images/myproject_tab.png" alt="마이프로젝트"/></div> -->
				<div v-if="isReg" class="sco_prj_tab">
					<div class="tab-type4">
						<ul class="tab">
							<li class="on" @click="move('PRJ301M01')">
								<a href="javascript:void(0);">공급기업 지정 프로젝트 등록</a>
							</li>
							<!-- <li @click="move('PRJ301M02')">
								<a href="javascript:void(0);">일반 구인 프로젝트 등록</a>
							</li>   -->
						</ul>
					</div>
				</div>
				<div v-else class="myprj_tab absol"><img src="/images/myproject_tab.png" alt="마이프로젝트"/></div>
				<div class="contents-body myprj mt-1-">
					<div class="my-resume">
						<div v-if="!mberDiv" class="resume-box" style="border-bottom: none;">
							<div class="tit enroll hr">&#039;{{this.$route.params.projectNm}}&#039;</div>
							<p class="mt-2" style="margin-bottom : 20px">수행사 : {{this.$route.params.corpNm}}</p>
						</div>
						<div v-else class="resume-box" style="border-bottom: none;">
							<div class="tit enroll hr">공급기업 지정 프로젝트 등록</div>
							<p style="color : red; margin-bottom : 20px">※전체 항목은 필수 입력 입니다.</p>
						</div>
						<!-- 프로젝트 구분 -->
						<div class="resume-box" :class="input.projectDivCd != '31' ? 'pb-9-5' : ''">
							<div class="tit enroll">프로젝트 구분</div>
							<p style="color : red;">※모든 프로젝트는 승인된 이후에 기술인재에게 노출됩니다.</p>
							
							<SelectComp tagName="div" class="info mt-4 prj" type="radio" list="32:제안단계,31:수행예정" v-model="input.projectDivCd" defValue="32" @change="changeDiv()" :disabled="!mberDiv"/>
							<nbsp n="3"/>
							<p v-if="input.projectDivCd == '31'" class="date">수주일 : </p>
							<SelectDate v-if="input.projectDivCd == '31'" tagNm="div" type="date" class="select-box" classY="wd02" classM="wd01" classD="wd01" from="Y-2" title="일" rules="required" v-model="input.contractYyyymmdd" :disabled="!mberDiv"/>
						</div>

						<!-- 프로젝트명 -->
						<div class="resume-box">
							<div class="tit enroll">프로젝트명</div>
							<div class="info">
								<div class="Board" style="margin-bottom: 0;margin-top: 0;">
									<table class="Board_type2" style="table-layout: auto;">
										<colgroup>
											<col width="*">
										</colgroup>
										<tbody>
											<tr>
												<td class="tech">
													<InputComp type="text" classNm="enroll" placeholder="프로젝트명을 입력 해 주세요!" 
														v-model="input.projectNm" 
														title="프로젝트명"
														rules="maxLength:100"
														:disabled="!mberDiv"
													/>
												</td>
											</tr>
											<tr>
												<td class="tech">
													<InputComp type="text" classNm="enroll" placeholder="간략한 프로젝트 개요를 입력 해 주세요!" 
														v-model="input.projectDtlDesc" 
														title="프로젝트 개요"
														rules="maxLength:100"
														:disabled="!mberDiv"
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<!-- 프로젝트기간 -->
						<div class="resume-box">
							<div class="tit enroll">프로젝트기간</div>
							<div class="info">
								<div class="Board" style="margin-bottom: 0;margin-top: 0;">
									<table class="Board_type2" style="table-layout: auto;">
										<colgroup>
											<col width="*">
										</colgroup>
										<tbody>
											<tr>
												<td class="tech">
													<SelectDate v-model="input.projectStartYyyymmdd" :from="fromDate" :to="toDate" type="date" :disabled="input.projectStartYyyymmdd < fromDate || !mberDiv" :rules="{'required':true}" /><nbsp/>
													<p>부터</p>
													<SelectDate v-model="input.projectEndYyyymmdd" :from="dateLimit(fromDate, input.projectStartYyyymmdd)" :to="toDate" type="date" :disabled="input.projectEndYyyymmdd < fromDate || !mberDiv" :rules="{'required':true}" /><nbsp/>
													<p>까지</p>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<!-- 고객사 -->
						<div class="resume-box">
							<div style="width: 50%; float: left;">
								<div class="tit enroll">고객사</div>
								<div class="info">
									<div class="Board" style="margin-bottom: 0;margin-top: 0;">
										<table class="Board_type2" style="table-layout: auto;">
											<colgroup>
												<col width="*">
											</colgroup>
											<tbody>
												<tr>
													<td class="tech">
														<InputComp type="text" classNm="task py-2 px-4" placeholder="고객사를 입력해 주세요!" 
														v-model="input.clientNm" 
														title="고객사"
														rules="maxLength:100"
														:disabled="!mberDiv"
														/>
														<!-- <input type="text" class="task py-2 px-4" placeholder="수행처를 입력해 주세요!"> -->
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div style="float: right;">
								<div class="tit enroll">고객사  CI(기업 이미지) 등록</div>
								<div class="info">
									<div class="filebox">
										<file-select type="file" v-model="clientImgFile" :name="input.clientFileNm" accept=".jpg,.jpeg,.png" :disabled="!mberDiv"/>
										<!-- <file-select type="file" v-model="temp.excelFile" accept=".xls,.xlsx" @input="excelUpload"/> -->
										<!-- <input type="text" placeholder="파일선택"> -->
										<!-- <img class="file_search" src="/images/tec/prf/file_search.png" alt="파일찾기"/> -->
									</div>
								</div>
							</div>
						</div>

						<!-- 산업분야 -->
						<div class="resume-box">
							<div class="tit enroll">고객사 산업분야</div>
							<div class="info">
								<div class="Board" style="margin-bottom: 0;margin-top: 0;">
									<table class="Board_type2" style="table-layout: auto;">
										<colgroup>
											<col width="*">
										</colgroup>
										<tbody>
											<tr>
												<td class="special">
												<SelectComp @change.native="changeReset(indList)" class="special" type="select" v-model="indList.industry" :defValue="indList.industryDetail && indList.industryDetail.substr && indList.industryDetail.substr(0, 1) || '' " cdId="PRO105" title="산업분야 선택" :disabled="!mberDiv"/><nbsp/>
												<SelectComp class="special" type="select" v-model="indList.industryDetail" cdId="PRO143" :filter="indList.industry" title="상세 산업분야 선택"  :rules="[{rule: ruleRequired, args: indList.industry}]" :disabled="!mberDiv"/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<!-- 공급기업 지정 -->
						<div class="resume-box" :class="mberDiv ? 'last' : ''" v-if="mberDiv">
							<div class="tit enroll">
								<img src="/images/check_02.png" style="vertical-align: sub;" />공급기업 지정<span class="ml-5">* 돋보기를 클릭하면 생성되는 팝업창에서 공급기업을 선택해 주세요!</span>
							</div>
							<div class="corp-search-container mr-0">
								<div class="btn" @click="openScoList()">
									<span v-if="scoFieldList.length > 0">선택한 공급기업</span>
									<span v-else>공급기업 선택</span>
									<img src="/images/icon_srch.png"/>
								</div>
								<!-- 닫힌 상태에서는 open class를 삭제 해주세요 -->
								<div :class="[scoFieldList.length > 0 ? 'container' : 'container displayNone']">
									<div class="result-area sco">
										<!-- <ul>
											<li v-for="(sco, index) in scoFieldList" :key="index">
                                        		<a :class="[sco.checked ? 'active' : '']" @click="clickSco(sco, index)">{{sco.corpNm}}</a>
                                    		</li>
										</ul> -->
										<table>
											<colgroup>
												<col width="5%">
												<col width="25%">
												<col width="17%">
												<col width="48%">
												<col width="5%">
											</colgroup>
											<tbody>
												<tr v-for="(scoInfo, index) in scoFieldList" :key="scoInfo.corpSeq">
													<td class="ta-center" style="font-family: NanumSquareB;">{{ index + 1}}</td>
													<td><div class="corpNm">{{ scoInfo.corpNm }}</div></td>
													<td>{{ scoInfo.bizRegNo | bizNo }}</td>
													<td><div class="companyAddrInfo">{{ scoInfo.companyAddrInfo }}</div></td>
													<td><button class="btn-delete" @click="delSco(index)">삭제</button></td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>

								<div :class="[scoFieldList.length > 0 ? 'reset-area mb-0' : 'reset-area mb-0 displayNone']">
									<button class="btn-reset" @click="resetScoFieldList()">초기화</button>
								</div>

								<!-- <div class="selected-area mb-0"> -->
								<!-- <div :class="[scoFlag == true ? 'selected-area mb-0' : 'selected-area mb-0 displayNone']">
									<ul>
										<li v-for="(list, index) in scoFieldList" :key="index">{{list.corpNm}}<button class="btn-delete" @click="delSco(list, index)">삭제</button></li>
									</ul>
									<div class="aside">
										<button class="btn-reset" @click="resetScoFieldList()">초기화</button>
									</div>
								</div> -->

							</div>
						</div>
						
						<!-- 근무지 -->
						<div class="resume-box" v-if="!mberDiv">
							<div class="tit enroll">근무지</div>
							<div class="info">
								<div class="Board" style="margin-bottom: 0;margin-top: 0;">
									<table class="Board_type2" style="table-layout: auto;">
										<colgroup>
											<col width="*">
										</colgroup>
										<tbody>
											<tr>
												<td class="tech">
													<InputComp type="text" classNm="enroll" placeholder="프로젝트를 수행할 주소지를 입력 해 주세요!" 
														v-model="input.projectLocCont" 
														title="근무지"
														rules="maxLength:100"
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<list-box title="직무분야" type="duty" ref="duty" class="job myprj" v-model="dutyFieldList" v-slot="slot" :mberSeq="mberSeq" v-if="!mberDiv">
							<div class="Board" style="margin-bottom: 0;margin-top: 0;">
								<table class="Board_type2" style="table-layout: auto;">
									<colgroup>
										<col width="*">
									</colgroup>
									<tbody>
										<tr>
											<td class="special">
												<SelectComp class="special" type="select" v-model="slot.item.dutyFilter" :defValue="slot.item.dutyDetail && slot.item.dutyDetail.substr && slot.item.dutyDetail.substr(0, 2) || '' " cdId="PRO141" title="직무분야 선택" :disabled="slot.item.dutyOld"/><nbsp/>
												<SelectComp class="special" type="select" v-model="slot.item.dutyDetail" cdId="PRO142" :filter="slot.item.dutyFilter" title="상세 직무분야 선택" :rules="[{rule: ruleRequired, args: slot.item}]" :disabled="slot.item.dutyOld"/>
												<div class="levelbox" style="width:393px">
													<img class="cancel" src="/images/tec/prf/cancel_btn.png" alt="취소" @click="removeList(dutyFieldList, slot.index)"/>	
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</list-box>

						<!-- 개발기술 -->
						<div class="resume-box" v-if="!mberDiv">
							<div class="tit enroll">개발기술</div>
							<div class="info">
								<div class="Board" style="margin-bottom: 0;margin-top: 0;">
									<table class="Board_type2" style="table-layout: auto;">
										<colgroup>
											<col width="*">
										</colgroup>
										<tbody>
											<tr>
												<td class="tech">
													<InputComp type="text" classNm="enroll" placeholder="프로젝트 개발에 필요한 기술을 입력 해 주세요!" 
														v-model="input.devTechCont" 
														title="개발기술"
														rules="maxLength:100"
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<!-- 모집 자격 및 인원 -->
						<div class="resume-box recruit" v-if="!mberDiv">
							<div class="tit enroll">모집 자격 및 인원</div>
							<!-- 1set -->
							<div v-for="(recr, index) in recrFieldList" :key="index"  class="info">
								<div class="Board" style="margin-bottom: 0;margin-top: 0;">
									<table class="Board_type2" style="table-layout: auto;">
										<colgroup>
											<col width="*">
										</colgroup>
										<tbody>
											<tr v-if="index != 0"><td><br/><br/></td></tr>
											<tr>
												<td class="project">
													<InputComp type="text" classNm="charge-task" placeholder="담당업무를 입력 해 주세요!" 
														v-model="recr.workCont" 														
														title="모집자격 및 인원의 담당업무"
														rules="required:true,maxLength:100"
														:showError="false"
													/>
													<InputComp type="text" classNm="personnel" placeholder="인원" v-model="recr.persCnt"/>
													<nbsp/>
													<SelectComp type="select" cdId="PRO106" title="기술등급" v-model="recr.techGradeCd" class="year qf"/>
													<nbsp/>
													<SelectComp type="select" cdId="PRO107" title="경력" v-model="recr.careerTermCd" class="year qf"/>
													<nbsp/><img class="cancel" src="/images/tec/prf/cancel_btn.png" alt="취소" @click="removeList(recrFieldList, index)"/>
												</td>
											</tr>
											<tr>
												<td class="project">
													<p class="term">참여기간</p><nbsp/>
													<SelectDate title="참여시작일" v-model="recr.joinTermStartYyyymmdd" :from="dateLimit(fromDate, input.projectStartYyyymmdd)" :to="dateLimit(toDate, input.projectEndYyyymmdd)" type="date" :disabled="recr.joinTermStartYyyymmdd < fromDate"/>
													<p class="itv">부터</p><nbsp/> 
													<SelectDate title="참여종료일" v-model="recr.joinTermEndYyyymmdd" :from="dateLimit(fromDate, [recr.joinTermStartYyyymmdd, input.projectStartYyyymmdd])" :to="dateLimit(toDate, input.projectEndYyyymmdd)" type="date" :disabled="recr.joinTermEndYyyymmdd < fromDate"/>
													<p class="itv">까지</p><nbsp/>
												</td>
											</tr>
											<tr>
												<td class="project">
													<p class="term">모집기간</p><nbsp/>
													<SelectDate title="모집시작일" v-model="recr.recrTermStartYyyymmdd" :from="fromDate" :to="dateLimit(toDate, input.projectEndYyyymmdd)" type="date" :disabled="recr.recrTermStartYyyymmdd < fromDate"/>
													<p class="itv">부터</p><nbsp/> 
													<SelectDate title="모집종료일" v-model="recr.recrTermEndYyyymmdd" :from="dateLimit(fromDate, [recr.recrTermStartYyyymmdd, input.projectStartYyyymmdd])" :to="dateLimit(toDate, input.projectEndYyyymmdd)" type="date" :disabled="recr.recrTermEndYyyymmdd < fromDate"/>
													<p class="itv">까지</p><nbsp/>
												</td>
											</tr>																						        
										</tbody>
									</table>
								</div>
							</div>
							<!-- 추가버튼 -->
							<div class="plus-btn"><img src="/images/tec/prf/plus_btn.png" alt="추가버튼" @click="addList(recrFieldList)"/></div>
						</div>
						<!-- 프로젝트 수행내용 -->
						<div class="resume-box last" v-if="!mberDiv">
							<div class="tit enroll">프로젝트 수행내용 <span class="btm">※ 프로젝트 수행내용을 상세히 입력 해 주시면, 기술인재의 지원에 도움이 됩니다!</span></div>
							<InputComp type="textarea" classNm="perform" placeholder="상세한 프로젝트 내용을 입력 해 주세요!" v-model="input.projectWorkCont"/>
						</div>
						<!-- 작성예시 -->
						<div class="speech-bubble" v-if="!mberDiv">
							<div class="tit">※ 작성예시</div><br/>
							프로젝트 소개<br/>
							- 발주사 : OO은행<br/><br/>
							프로젝트 진행 상황<br/>
							- 요구사항 분석 또는 설계 문서가 있습니다.<br/><br/>
							전체 시스템 구성<br/>
							- OO의 개발 플랫폼(mquics) 을 사용합니다. ( 프레임워크에 대한 개발 가이드 제공 )<br/><br/>
							전체 프로젝트 일정 <br/>
							- 사업 일정 : 21년 8월초 착수함<br/>
							- 개발 착수 일정 : 21년 8월 초순 (투입 마감 일정 : 8월말, 착수 일정은 8월 초순 ~ 8월말 사이에서 조율 가능)<br/>
							- 개발 종료 일정 : 22년 3월 중순<br/><br/>
							&lt; 상세 업무 내용 &gt; <br/>
							모집 인원 : 프론트엔드 개발 10명<br/>
							- 분석/설계 1명<br/>
							- 개발 8명<br/>
							- Sub 개발 1명<br/><br/>
						</div>
					</div>
				</div>
				<!-- 하단 버튼 -->
				<div class="select_btn">
					<div class="btn_01" @click="$router.go(-1)">취소</div>
					<div v-if="isReg" class="btn_03" style="background-color: #17A1FF;" @click="mberDiv ? saveParentProjectInfo() : saveProjectInfo()">등록완료</div>
					<div v-if="!isReg" class="btn_03" style="background-color: #FF681D;" @click="mberDiv ? saveParentProjectInfo() : saveProjectInfo()">수정하기</div>
					<div v-if="!isReg && ((mberDiv && this.input.parentProjectSeq) || (!mberDiv && this.input.projectSeq))" class="btn_del" @click="deleteProject()">
						삭제하기
						<span><img src="/images/prjt_x_btn.png"></span>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

/**
 * 프로젝트 등록/수정 화면
 */
import listBox from "@/components/highpro/ResumeListBox.vue";
import fileSelect from "@/components/FileSelect.vue";
export default {

	components : {
		listBox,
		fileSelect
	},

	data() {
		var limitYear = 3;
		return {
			input: {
				projectSeq : null,
			},
			clientImgFile : {},
			recrFieldList : [{}], 	/* 모집자격 및 인원 (지원분야) */
			dutyFieldList : [],   /* 직무분야 */
			indList : [],	/* 산업분야 */
			fromDate : new Date().add(-limitYear, 'year').format('yyyyMMdd'),	//수정가능 년도 2년전
			toDate   : new Date().add( limitYear, 'year').format('yyyyMMdd'),	//수정가능 년도 2년후
			isReg : true,
			mberSeq : this.$store.state.userInfo.mberSeq,
			//scoFlag : false,
			//scoList : [],
			scoFieldList : [],
		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
		// this.getScoList();
	},
	mounted() {
		// console.log('mounted');	
		if(this.$route.query.projectSeq != undefined && this.$route.query.projectSeq != '' && this.$store.state.userInfo.mberDivCd == '22') {
			this.input.parentProjectSeq = this.$route.query.projectSeq;
			this.isReg = false;
			this.getParentProjectInfo();
		}
		if(this.$route.query.projectSeq != undefined && this.$route.query.projectSeq != '' && this.$store.state.userInfo.mberDivCd == '31') {
			this.input.projectSeq = this.$route.query.projectSeq;
			this.isReg = false;
			this.getProjectInfo();
		}

		if(this.$store.state.userInfo.mberDivCd == '31' && this.isReg == true) {
			this.input.parentProjectSeq = this.$route.params.parentProjectSeq
			this.getParentProjectInfo();
		}

		// if(this.$store.state.userInfo.mberDivCd == '22') {
		// 	this.getMyCorpName();
		// }
		
		// if(this.$route.params.projectSeq != undefined && this.$route.params.projectSeq != '') {
		// 	this.input.projectSeq = this.$route.params.projectSeq;
		// 	this.isReg = false;
		// 	this.getProjectInfo();
		// }
		
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		getProjectInfo() {
			var input = {
				projectSeq : this.input.projectSeq
			}

			this.$.httpPost('/api/prj/getProjectInfo', input)
				.then(res => {
					//console.log('getProjectInfo RESULT', res.data);
					this.input = res.data.projectInfo;
					this.recrFieldList = res.data.projectRecrFieldList;

					// 콤마 구분자의 직무분야를 리스트로 변환
					if(this.input.dutyFieldCont) {
						var tempArr = this.input.dutyFieldCont.split(',');
						this.dutyFieldList = [];					
						for(var i in tempArr) {
							var list = {};
							this.dutyFieldList.push(list);
							this.dutyFieldList[i].dutyDetail = tempArr[i];
							this.dutyFieldList[i].dutyOld = true;
						}
					}

					// if(this.input.choiceCorpList) {
					// 	this.scoFlag = true;
					// 	var tempList = this.input.choiceCorpList.split(',');
					// 	for(var c of tempList) {
					// 		for(var a=0; a<this.scoList.length; a++) {
					// 			if(this.scoList[a].corpSeq == c) {
					// 				this.scoList[a].checked = true;
					// 				this.scoList[a].index = a;
					// 				this.scoFieldList.push(this.scoList[a]);
					// 				break;
					// 			}
					// 		}
					// 	}
					// }
					
					if(res.data.projectInfo.projectIndtypeClassCd) {
						this.indList.industryDetail = res.data.projectInfo.projectIndtypeClassCd;
					}
					//--					
					// console.log(this.input);
					window.scroll(0, 0);
				}).catch(this.$.httpErrorCommon);
		},

		addList(list) {
			var temp = {};
			list.push(temp);
			this.$forceUpdate();
		},

		removeList(list, index) {
			var param = {
				projectRecrFieldSeq : list[index].projectRecrFieldSeq
			}
			this.$.httpPost('/api/prj/dco/pmg/getPrjAppCount', param)
                .then(res => {
                    if(res.data.recrCount > 0) {
						alert("해당 모집분야에 지원한 인재가 있으므로 삭제할 수 없습니다.");
					} else {
						list.splice(index, 1);
					}
                }).catch(this.$.httpErrorCommon);

			if(list.length == 0) {
				this.addList(list);
			}
			this.$forceUpdate();
		},

		// 프로젝트 상태 계산 - 승인전 제안 or 수행예정
		calculateProjectStatus() {
			const now = new Date().format('yyyyMMdd');
			//console.log(now);

			let status = '';
			
			if (this.input.projectDivCd === '32' && !this.input.contractYyyymmdd) {
				status = 'PRJ407M02'; // 제안단계
			} else if (this.input.projectDivCd === '31' && this.input.projectStartYyyymmdd <= now && this.input.projectEndYyyymmdd >= now) {
            	status = 'PRJ407M04'; //수행중
			} else if (this.input.projectDivCd === '31' && this.input.projectStartYyyymmdd > now) {
                status = 'PRJ407M03'; //수행예정
            } else if (this.input.projectDivCd === '31' && this.input.projectEndYyyymmdd < now) {
                status = 'PRJ407M05'; //완료
            }

			return status;
		},

		saveProjectInfo() {
			//console.log('saveProjectInfo', JSON.stringify(this.input, null, 4 );	

			// 직무분야 리스트 -> 콤마 구분자로 변환
			if(this.dutyFieldList[0].dutyDetail != undefined) {
				var dutyFieldCont = '';

				for(var i=0; i<this.dutyFieldList.length; i++) {
					if(this.dutyFieldList[i].dutyDetail instanceof Error) {
						continue;
					}
					dutyFieldCont += this.dutyFieldList[i].dutyDetail;				
					if(i < this.dutyFieldList.length-1) {
						dutyFieldCont += ',';
					}
				}
				this.input.dutyFieldCont = dutyFieldCont;
			}
			
			// 지정공급기업목록 변환
			// if(this.scoFieldList.length > 0) {
			// 	var scoFieldCont = '';
			// 	for(var j = 0; j<this.scoFieldList.length; j++) {
			// 		scoFieldCont += this.scoFieldList[j].corpSeq;
			// 		if(j < this.scoFieldList.length-1) {
			// 			scoFieldCont += ',';
			// 		}
			// 	}

				this.input.choiceCorpList = '';
			// } else {
			// 	alert("프로젝트에 참여시킬 공급기업을 선택해주세요!");
			// 	return;
			// }
			

			this.input.recrFieldList = this.recrFieldList;
			this.input.industryDetail = this.indList.industryDetail;

			if(this.input.projectNm == "" || this.input.projectNm == undefined) {
				alert("프로젝트명은 필수로 입력해주세요.");
			}

			// 산업분야 체크
			if(this.input.industryDetail == "" || this.indList.industryDetail == undefined) {
				alert("선택되지 않은 산업분야가 있습니다.");
				return;
			}

			// 직무분야 체크
			for(var val of this.dutyFieldList) {
				if(val.dutyFilter == undefined){
					alert("선택되지 않은 직무분야가 있습니다.");
					return;
				}
				if(val.dutyDetail == undefined || val.dutyDetail == "" || val.dutyDetail instanceof Error) {
					alert("선택되지 않은 상세 직무분야가 있습니다.");
					return;
				}
			}

			if(this.input.projectDivCd == '31' && this.input.contractYyyymmdd == '') {
				alert("수주일을 입력해주세요!");
			}
			
			// 모집 자격 및 인원에 대한 유효성 검사 추가
			for (const recr of this.recrFieldList) {
				// 담당업무, 인원, 기술등급, 경력, 참여기간 및 모집기간의 시작일과 종료일에 대한 검사
				if (!recr.workCont || recr.persCnt === undefined || !recr.techGradeCd || !recr.careerTermCd || !recr.joinTermStartYyyymmdd || !recr.joinTermEndYyyymmdd || !recr.recrTermStartYyyymmdd || !recr.recrTermEndYyyymmdd) {
					alert("선택되지 않은 모집 자격 및 인원 요건이 있습니다.");
					return; 
				}
			}
			
			var files = [];

			if(this.clientImgFile instanceof File){
				//첨부파일이 있을 경우 첨부파일을 추가한다.
				files.push(this.clientImgFile);
                this.input.clientFileNm = this.clientImgFile.name;
				this.input.clientFileYn = 'Y';
			}
            
			// console.log(this.input);
			this.$.fileUpload('IMG', files,'/api/prj/sco/choice/saveScoProjectInfo', this.input)
				.then(() => {
					if(this.isReg) {
						alert('등록 되었습니다.');
						//this.$router.push('/');
						//프로젝트 상태 구분
						const projectStatusRoute  = this.calculateProjectStatus();
						//프로젝트 상태별 해당 목록화면 리디렉션
							if(projectStatusRoute){
								this.$router.push({ name: projectStatusRoute});
							}
					} else {
						alert('수정 되었습니다.');
						this.$router.go(-1);
					}
				}).catch(this.$.httpErrorCommon);
		},

		dateLimit(defVal, checks){
			if(!Array.isArray(checks)) checks = [checks];
			for(var i in checks){
				if(typeof checks[i] == 'string')
					return checks[i]
			}
			return defVal;
		},	

		ruleRequired(v, item, msg){
			if(!v){
				//console.log('', 'ruleRequired', v, item, msg);
				for(var key in item){
					if(key.indexOf('_') != 0 && item[key]){
						if(item[key] instanceof Error){
							//console.log('', 'ruleRequired', key, item[key].message);
						} else {
							return msg || '항목 입력 시 필수 입력입니다.'
						}
					}
				}
			}
			return true;
		},

		changeReset(item) {				
			this.indList = [];					
			this.indList.industry = item.industry;
		} ,
		
		openScoList() {
			// if(!this.scoFlag) {
			// 	this.scoFlag = true;
			// } else {
			// 	this.scoFlag = false;
			// }

			var param ={
				scoFieldList : this.scoFieldList,
			}
			
			this.$.popup('/dco/pmg/prj/PRJ301P03', param)
				.then(res => {
					if(res.chkFlag){
						this.scoFieldList = res.tempScoFieldList.slice();
					}
				});
		},

		// getScoList() {
		// 	this.$.httpPost('/api/prj/dco/pmg/getScoList')
        //         .then(res => {
        //             this.scoList = res.data;
        //         }).catch(this.$.httpErrorCommon);
		// },

		// clickSco(sco, index) {
		// 	if(sco.checked == undefined || sco.checked == false) {
		// 		sco.checked = true;
		// 		sco.index = index;
		// 		this.scoFieldList.push(sco);		
		// 	} else {
		// 		sco.checked = false;
		// 		for(var i=0; i<this.scoFieldList.length; i++) {
		// 			if(this.scoFieldList[i].corpSeq == sco.corpSeq) {
		// 				this.scoFieldList.splice(i,1);
		// 			}
		// 		}
		// 	}			
		// },

		delSco(index) {
			this.scoFieldList.splice(index,1);
			// this.scoList[list.index].checked=false;
		},

		resetScoFieldList() {
			//console.log(this.scoFieldList);
			// for(var list of this.scoFieldList){
			// 	this.scoList[list.index].checked = false;
			// }
			// this.scoFieldList.splice(0,this.scoFieldList.length);

			this.scoFieldList.splice(0);
		},

		move(page) {
			if(this.isReg) {
				this.$router.push({name : page})
			} 
		},

		changeDiv() {
			// console.log(this.input);
			this.input.contractYyyymmdd = '';
		},

		saveParentProjectInfo() {

			var param = this.input;

			if(this.input.projectNm == "" || this.input.projectNm == undefined) {
				alert("프로젝트명은 필수로 입력해주세요.");
			}

			if(this.input.projectStartYyyymmdd == "" || this.input.projectStartYyyymmdd == undefined || this.input.projectEndYyyymmdd == "" || this.input.projectEndYyyymmdd == undefined) {
				alert("프로젝트 기간을 입력해주세요.");
			}

			if(this.input.projectDivCd == '31' && (this.input.contractYyyymmdd == '' || this.input.contractYyyymmdd == undefined)) {
				alert("수주일을 입력해주세요!");
			}

			if(this.input.clientNm == '' || this.input.clientNm == undefined) {
				alert("고객사명을 입력해주세요.");
				return;
			}
			
			if(this.indList.industryDetail == '' || this.indList.industryDetail == undefined) {
				alert("산업분야를 선택해주세요.");
				return;
			}

			// 지정공급기업목록 변환
			if(this.scoFieldList.length > 0) {
				var scoFieldCont = '';
				for(var j = 0; j<this.scoFieldList.length; j++) {
					scoFieldCont += this.scoFieldList[j].corpSeq;
					if(j < this.scoFieldList.length-1) {
						scoFieldCont += ',';
					}
				}

				this.input.choiceCorpList = scoFieldCont;
			} else {
				alert("프로젝트에 참여시킬 공급기업을 선택해주세요!");
				return;
			}
			

			this.input.industryDetail = this.indList.industryDetail;

			var files = [];

			if(this.clientImgFile instanceof File){
				//첨부파일이 있을 경우 첨부파일을 추가한다.
				files.push(this.clientImgFile);
                this.input.clientFileNm = this.clientImgFile.name;
				this.input.clientFileYn = 'Y';
			}

			param.scoFieldList = this.scoFieldList;
			param.files = files;
			this.$.popup('/dco/pmg/prj/PRJ301P01', param)
				.then(res => {
					if(res) {
						if(this.isReg) {
						alert('등록 되었습니다.');
							//this.$router.push('/');
							this.$router.push({name: 'PRJ309M01' });
						} else {
							alert('수정 되었습니다.');
							this.$router.go('-1');
						}
					}
				});

			
		},

		getParentProjectInfo() {
			var input = {
				parentProjectSeq : this.input.parentProjectSeq
			}
			// console.log(input);
			this.$.httpPost('/api/prj/sco/choice/getParentProjectInfo', input)
				.then(res => {
					// console.log('getParentProjectInfo RESULT', res.data);
					this.input = res.data.projectInfo;
					this.scoFieldList = res.data.choiceCorpInfoList;
					
					// if(this.scoFieldList) {
					// 	this.scoFlag = true;

					// 	var tempList = this.input.choiceCorpList.split(',');
					// 	for(var c of tempList) {
					// 		for(var a=0; a<this.scoList.length; a++) {
					// 			if(this.scoList[a].corpSeq == c) {
					// 				this.scoList[a].checked = true;
					// 				this.scoList[a].index = a;
					// 				this.scoFieldList.push(this.scoList[a]);
					// 				break;
					// 			}
					// 		}
					// 	}

					// }
					if(res.data.projectInfo.projectIndtypeClassCd) {
						this.indList.industryDetail = res.data.projectInfo.projectIndtypeClassCd;
					}
					// this.input.projectSeq = this.input.parentProjectSeq;
					window.scroll(0, 0);
				}).catch(this.$.httpErrorCommon);
		},

		deleteProject() {
			if (this.mberDiv && this.input.apprYn == 'Y') {
				alert("승인된 프로젝트는 삭제할 수 없습니다.");
			} else if(!this.mberDiv && this.input.appCount > 0) {
				alert("프로젝트에 지원한 인재가 있으므로 프로젝트를 삭제할 수 없습니다.");
			} 
			else {
				this.$.popup('/dco/pmg/prj/PRJ301P02', {projectNm : this.input.projectNm})
				.then(res => {
					if(res) {
						if(this.mberDiv) {
							this.$.httpPost('/api/prj/dco/pmg/deleteParentProject', this.input)
							.then(() => {
								alert("삭제되었습니다.");
								var params = {
									caller : {
										name: this.$route.name,
										params: this.input,
									}
								}
								this.$router.push({name: 'PRJ309M01', params });
							})
							.catch(err => {
								alert(err.response.data.error_description);
							});
						} else {
							if(this.input.appCount > 0) {
								alert("프로젝트에 지원한 인재가 있으므로 프로젝트를 삭제할 수 없습니다.");
							} else {
								// this.input.recrFieldList = this.recrFieldList;
								this.$.httpPost('/api/prj/sco/deleteScoProject', this.input)
									.then(res => {
										
										if(res.data != 0) {
											alert("프로젝트에 지원한 인재가 있으므로 프로젝트를 삭제할 수 없습니다.");
										} else {
											alert("삭제되었습니다.");

											var params = {
												caller : {
													name: this.$route.name,
													params: this.input,
												}
											}
											this.$router.push({name: 'PRJ406M01', params });
										}
																				
									})
									.catch(err => {
										alert(err.response.data.error_description);
									});
							}
							
						}
						
					}
						
					});
			}
		},

		// getMyCorpName() {
		// 	this.$.httpPost('/api/prj/sco/choice/getMyCorpName', {mberSeq : this.$store.state.userInfo.mberSeq})
        //     .then(res => {
        //         this.input.parentCorpNm = res.data.corpNm;
        //     }).catch(this.$.httpErrorCommon);
		// },
		

			

		// validCheck() {

		// 	// 프로젝트명
		// 	if(this.input.projectNm == undefined || this.input.projectNm == '') {
		// 		alert('프로젝트명을 입력하세요.');
		// 		this.$refs.ref_projectNm.$refs.input.focus();
		// 		return false;
		// 	}

		// 	if(this.input.recrFieldList != undefined) {
		// 		for(var i=0; i<this.input.recrFieldList.length; i++) {
		// 			if(this.input.recrFieldList[i].workCont == undefined || this.input.recrFieldList[i].workCont == '') {
		// 				alert('모집 자격 및 인원의 담당업무를 입력 해 주세요.');
		// 				this.$refs.ref_recrWorkCont[i].$refs.input.focus();
		// 				return false;
		// 			}
		// 		}
		// 	}

		// 	// TODO : 유효성 체크 항목 추가 필요

		// 	return true;
		// },

	},

	computed : {
		mberDiv() {
			// 수요 Y 공급 N
			if(this.$store.state.userInfo.mberDivCd == '22') return true
			return false;
		}
	}
}
</script>
